<template lang="pug">
layout(:title="$route.name")
  Desktop(:local="local",:payment_sys_config="payment_sys_config", :receipt="receipt")
  //- Desktop(:local="local",:payment_sys_config="payment_sys_config", :receipt="receipt",v-if="$vuetify.breakpoint.smAndUp")
  //- Mobile(:local="local" v-else)
</template>

<script>
import Desktop from "../components/Desktop/Index.vue";
import Mobile from "../components/Mobile/Index.vue";
import { mapActions } from "vuex";
import { EventBus } from "@/main";

export default {
  components: {
    Desktop,
    Mobile
  },
  data() {
    return {
      local: {
        list_tables: []
      },
      payment_sys_config: {},
      receipt: {}
    };
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    saveLocal(data) {
      setTimeout(() => {
        this.$store.dispatch("enableLoading");
        this.$run("marketplaces/set-config-local", {
          local: data
        })
          .then(() => {
            this.$store.dispatch("disableLoading");
            this.createGlobalMessage({
              timeout: 6000,
              type: "sucess",
              message: "Local salvo com sucesso!"
            });
          })
          .catch(e => {});
      }, 300);
    },
    savePaymentSysConfig(data) {
      setTimeout(() => {
        this.$store.dispatch("enableLoading");
        this.$run("marketplaces/set-config-payment-sys", {
          payment_sys_config: data
        })
          .then(() => {
            this.$store.dispatch("disableLoading");
            this.createGlobalMessage({
              timeout: 6000,
              type: "sucess",
              message: "Taxas salvas com sucesso!"
            });
          })
          .catch(e => {});
      }, 300);
    },
    saveReceiptConfig(data) {
      setTimeout(() => {
        this.$store.dispatch("enableLoading");
        this.$run("marketplaces/set-config-receipt", {
          receipt: data
        })
          .then(() => {
            this.$store.dispatch("disableLoading");
            this.createGlobalMessage({
              timeout: 6000,
              type: "sucess",
              message: "Configurações de recibo salvas com sucesso!"
            });
          })
          .catch(e => {});
      }, 300);
    },
    getLocal() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-local")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.local = res.data.local;
        })
        .catch(e => {});
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          const payment_sys_config = res.data.payment_sys_config;
          if (payment_sys_config.finishing) {
            this.payment_sys_config = payment_sys_config;
          } else {
            this.payment_sys_config = {
              ...payment_sys_config,
              finishing: { active: true, tax_porcentage: 30 }
            };
          }
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    }
  },
  created() {
    this.getLocal();
    this.getConfigPaymentSys();
    this.getConfigReceipt();
    EventBus.$on("saveLocal", data => this.saveLocal(data));
    EventBus.$on("savePaymentSysConfig", data => {
      this.savePaymentSysConfig(data);
    });
    EventBus.$on("saveReceiptConfig", data => this.saveReceiptConfig(data));
  }
};
</script>
