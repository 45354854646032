<template lang="pug">
div.expande-horizontal
    menuConfig(:view="showView")
    storeConfig(:store="store",v-if="showView === 'store'")
    localConfig(:local="local",v-if="showView === 'local'")
    tableConfig(:local="local",v-if="showView === 'table'")
    receiptConfig(:view="showView", :receipt="receipt",v-if="showView === 'recibo'")
    taxConfig(:payment_sys_config="payment_sys_config",v-if="showView === 'taxas'")
    deliveryConfig(:view="showView",v-if="showView === 'area-de-entrega'")
</template>

<script>
import menuConfig from "../Desktop/Menu/Index.vue";
import localConfig from "../Desktop/Local/Index.vue";
import tableConfig from "../Desktop/Table/Index.vue";
import receiptConfig from "../Desktop/Receipt/Index.vue";
import taxConfig from "../Desktop/Tax/Index.vue";
import deliveryConfig from "../Desktop/Delivery/Index.vue";
import storeConfig from "../Desktop/Store/Index.vue";
import { EventBus } from "@/main.js";
export default {
  props: ["local", "payment_sys_config", "receipt"],
  data() {
    return {
      showView: ""
    };
  },
  components: {
    menuConfig,
    localConfig,
    tableConfig,
    receiptConfig,
    taxConfig,
    deliveryConfig,
    storeConfig
  },
  methods: {},
  created() {
    const self = this;
    EventBus.$on("set-config-view", view => {
      self.showView = view;
      self.$forceUpdate();
    });
  }
};
</script>
