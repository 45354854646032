<template lang="pug">
div.expande-horizontal.centraliza
    v-dialog(width="300", v-model="newTableDialog" persistent)
        v-card(width="300")
            div.expande-horizontal.centraliza.wrap.fonte
                v-flex(xs12)
                  v-list(dense nav dark :color="$theme.secondary")
                    v-list-item
                      v-list-item-content
                        v-list-item-title Mesa
                        v-list-item-subtitle Edite ou cadastre mesas
                      v-list-item-action 
                        v-btn(icon @click="closeNewTableDialog")
                          v-icon(color="white") mdi-close 
                v-flex(xs12).pa-2
                    v-text-field(label="Número",v-model="newTable.number")
                v-flex(xs12).pa-2
                    v-text-field(label="Descrição",v-model="newTable.description")
                v-flex(xs12).pa-2
                    v-switch(label="Ativa",v-model="newTable.active")
                v-flex(xs12).pa-2
                    v-btn(dark :color="$theme.secondary" @click="saveEditable" v-if="indexTableEdited > 0 || indexTableEdited === 0") salvar
                    v-btn(dark :color="$theme.secondary" @click="cadastrarNovaMesa" v-else) cadastrar

    v-flex(xs10)
        div.expande-horizontal.column.pa-3
            div.expande-horizontal.fonte.grey--text.mb-6
                v-flex(xs12)
                    div.expande-horizontal(style="align-items: center;")
                        v-btn(@click="changeConfigView('local')",icon,:color="$theme.secondary")
                            v-icon mdi-arrow-left
                        h4.ml-2.fonte(:style="`color: ${$theme.secondary}`") CONFIGURAÇÃO DE MESAS
            //- div.expande-horizontal.fonte.grey--text.mb-3
            //-     v-flex(xs12)
            //-         div.expande-horizontal(style="align-items: center;")
            //-             v-btn(@click="changeTypeView",icon,color="#363636")
            //-                 v-icon mdi-format-list-checkbox
            //-             h5.ml-2.fonte(@click="changeTypeView",:style="`color: #363636;cursor: pointer;`") Modo de visualização em {{ typeView === 'list' ? 'bloco' : 'lista' }}
            div.expande-horizontal.fonte.grey--text.mb-3
                v-flex(xs12)
                    div.expande-horizontal(style="align-items: center;")
                        v-flex(xs3)
                            v-text-field(
                                flat
                                solo
                                dense
                                hide-details
                                background-color="#F0F0F0"
                                prepend-inner-icon="mdi-magnify"
                                v-model="search"
                                placeholder="Pesquisar"
                            )
                        v-spacer
                        v-btn(@click="openModalCreateTable",color="green" dark)
                            v-icon mdi-check-circle-outline
                            span cadastrar nova mesa

            div.expande-horizontal.fonte.grey--text.header-table.mb-3
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        span Mesas
                v-flex(xs4)
                    div.expande-horizontal.centraliza
                        span Descrição
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        span Ativar | Desativar
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        span QRCode
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        span Ações
            div.mb-3.expande-horizontal.item-table(v-for="(table, index) in tablesFiltereds")
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        h3 {{ table.number }}
                v-flex(xs4)
                    div.expande-horizontal.centraliza
                        span {{ table.description }}
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        v-switch(v-model="table.active" @change="changeStatus")
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        v-icon mdi-qrcode-scan
                v-flex(xs2)
                    div.expande-horizontal.centraliza
                        v-btn(@click="goToEditTable(table, index)" icon)
                            v-icon mdi-vector-point-edit
                        v-btn(@click="deleteTable(table)" icon)
                            v-icon mdi-delete-outline

</template>

<script>
import { EventBus } from "@/main.js";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["local"],
  data() {
    return {
      typeView: "list",
      newTable: {},
      newTableDialog: false,
      indexTableEdited: false,
      search: ""
    };
  },
  computed: {
    tablesFiltereds() {
      let search = this.search;
      let withIndexes = [];
      this.local.list_tables.map((table, index) => {
        withIndexes.push({
          ...table,
          order: index
        });
      });
      const filtereds = withIndexes.filter(item => {
        return (
          item.number.includes(search) || item.description.includes(search)
        );
      });
      return filtereds;
    }
  },
  methods: {
    closeNewTableDialog() {
      this.newTableDialog = false;
      this.indexTableEdited = false;
      this.$forceUpdate();
    },
    ...mapActions(["createGlobalMessage"]),
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    changeTypeView() {
      if (this.typeView === "list") {
        this.typeView = "block";
      } else {
        this.typeView = "list";
      }
    },
    goToEditTable(table) {
      const t = table;
      this.newTable = t;
      this.indexTableEdited = table.order;
      this.newTableDialog = true;
    },
    openModalCreateTable() {
      this.newTable = {
        number: "",
        description: "",
        ative: false
      };
      this.indexTableEdited = false;
      this.newTableDialog = true;
    },
    saveEditable() {
      const self = this;
      let exist = this.local.list_tables.filter(
        (t, i) =>
          t.number === self.newTable.number && i !== self.indexTableEdited
      );
      if (exist.length > 0) {
        this.createGlobalMessage({
          timeout: 6000,
          type: "info",
          message: "Há uma mesa com esse número"
        });
      } else {
        let newTables = this.local.list_tables;
        newTables[this.indexTableEdited] = this.newTable;

        this.local.list_tables = newTables;
        this.indexTableEdited = false;
        this.newTableDialog = false;
        this.newTable = {
          number: "",
          description: "",
          ative: false
        };
        EventBus.$emit("saveLocal", this.local);
      }
    },
    cadastrarNovaMesa() {
      const self = this;
      let exist = this.local.list_tables.filter(
        t => t.number === self.newTable.number
      );
      if (exist.length) {
        this.createGlobalMessage({
          timeout: 6000,
          type: "info",
          message: "Há uma mesa com esse número"
        });
      } else {
        this.local.list_tables.push(this.newTable);
        const local = this.local;
        EventBus.$emit("saveLocal", local);
        this.newTableDialog = false;
        this.newTable = {
          number: "",
          description: "",
          ative: false
        };
        this.$forceUpdate();
      }
    },
    deleteTable(table_to_delete) {
      this.local.list_tables = this.local.list_tables.filter(
        t => t.number !== table_to_delete.number
      );
      EventBus.$emit("saveLocal", this.local);
    },
    changeStatus() {
      setTimeout(() => {
        EventBus.$emit("saveLocal", this.local);
      }, 200);
    }
  }
};
</script>

<style>
.item-table {
  border-radius: 5px;
  border-left: 6px solid #010f50;
  height: 60px;
  align-items: center;
}
.header-table {
  border-radius: 5px;
  height: 60px;
  align-items: center;
  color: #b0b0b0;
  background-color: rgb(239, 236, 236);
}
</style>
