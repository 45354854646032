<template lang="pug">
v-flex(xs9).pl-6
    v-card.expande-horizontal.wrap.pa-6 
        div.expande-horizontal.centraliza.column
            div.expande-horizontal(v-if="$vuetify.breakpoint.smAndDown")
                v-btn(dark icon @click="changeConfigView('')")
                    v-icon(color="black") mdi-arrow-left
            div.config-local-avatar 
                img(width="80",height="100",src="img/config/taxs.svg")
            h4.fonte.pt-6.pb-12 Opções de Pagamento padrão
            v-tabs(:background-color="$theme.secondary", v-model="tax_tab", color="white", centered)
                v-tab DStone
                //- v-tab MenuRocket
            div.expande-horizontal.centraliza(v-if="tax_tab === 0")
                v-flex(xs8)
                    v-list(two-line,style="width: 100%;")
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-cash
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Dinheiro
                                    v-flex(xs4)
                                        v-text-field(
                                            outlined 
                                            dense
                                            v-if="payment_sys_config['money'].active"
                                            hide-details
                                            type="Number"
                                            suffix="%"
                                            placeholder="ex: 2.89"
                                            v-model="payment_sys_config['money'].tax_porcentage"
                                        )
                            v-list-item-action.pt-3
                                v-switch(@change="save",v-model="payment_sys_config['money'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-credit-card-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Cartão de Crédito
                                    v-flex(xs4)
                                        v-text-field(
                                            outlined 
                                            dense
                                            v-if="payment_sys_config['card'].active"
                                            suffix="%"
                                            hide-details
                                            type="Number"
                                            placeholder="ex: 2.89"
                                            v-model="payment_sys_config['card'].tax_porcentage"
                                        )
                            v-list-item-action.pt-3
                                v-switch(@change="save",v-model="payment_sys_config['card'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-credit-card-chip-outline
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Cartão de Débito
                                    v-flex(xs4)
                                        v-text-field(
                                            outlined 
                                            dense
                                            v-if="payment_sys_config['debit'].active"
                                            suffix="%"
                                            hide-details
                                            type="Number"
                                            placeholder="ex: 2.89"
                                            v-model="payment_sys_config['debit'].tax_porcentage"
                                        )
                            v-list-item-action.pt-3
                                v-switch(@change="save",v-model="payment_sys_config['debit'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                img(style="width: 27px;",src="img/config/pix.svg",color="#363636")
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Pix
                                    v-flex(xs4)
                                        v-text-field(
                                            outlined 
                                            dense
                                            v-if="payment_sys_config['pix'].active"
                                            suffix="%"
                                            hide-details
                                            type="Number"
                                            placeholder="ex: 2.89"
                                            v-model="payment_sys_config['pix'].tax_porcentage"
                                        )
                            v-list-item-action.pt-3
                                v-switch(@change="save",v-model="payment_sys_config['pix'].active",:color="$theme.secondary")
                        v-divider 
                        v-list-item
                            v-avatar
                                v-icon(size="31",color="#363636") mdi-account-hard-hat
                            v-list-item-content 
                                v-list-item-title
                                    span.fonte Acabamento
                                    v-flex(xs4)
                                        v-text-field(
                                            outlined 
                                            dense
                                            suffix="%"
                                            hide-details
                                            type="Number"
                                            placeholder="ex: 2.89"
                                            v-model="payment_sys_config['finishing'].tax_porcentage"
                                        )
                            v-list-item-action.pt-3
                                v-switch(disabled,v-model="payment_sys_config['finishing'].active",:color="$theme.secondary")
                        v-divider 
                    v-btn(@click="savePaymentSysConfig", :color="$theme.third", dark) Salvar
            div.expande-horizontal(v-if="tax_tab === 2")

</template>

<script>
import { EventBus } from "@/main";
export default {
  props: ["payment_sys_config"],
  data() {
    return {
      tax_tab: 0
    };
  },
  methods: {
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    savePaymentSysConfig() {
      EventBus.$emit("savePaymentSysConfig", this.payment_sys_config);
    }
  }
};
</script>

<style>
.config-local-avatar {
  width: 150px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
