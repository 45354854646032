<template lang="pug">
v-flex(md3).fonte
    v-card.expande-horizontal.pa-2.pt-6(min-height="300px" v-if="$vuetify.breakpoint.smAndDown")
        v-list.expande-horizontal.pa-0.ma-0(dense)
            //- v-list-item(:class="{ 'menu-config--active': view === 'infoloja' }",@click="changeView('infoloja')")
            //-     v-list-item-content
            //-         v-list-item-title
            //-             span.fonte.font-weight-bold(:class="{ 'white--text': view === 'infoloja' }") Informações da Loja
            //- v-list-item.mt-4(:class="{ 'menu-config--active': view === 'local' }",@click="changeView('local')")
            //-     v-list-item-content
            //-         v-list-item-title
            //-             span.fonte.font-weight-bold(:class="{ 'white--text': view === 'local' }") Consumo Local e Retirada
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
            v-list-item.mt-4(:class="{ 'menu-config--active': view === 'recibo' }",@click="changeView('recibo')")
                v-list-item-content
                    v-list-item-title
                        span.fonte.font-weight-bold(:class="{ 'white--text': view === 'recibo' }") Recibo
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
            v-list-item.mt-4(:class="{ 'menu-config--active': view === 'taxas' }",@click="changeView('taxas')")
                v-list-item-content
                    v-list-item-title
                        span.fonte.font-weight-bold(:class="{ 'white--text': view === 'taxas' }") Taxas
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
            //- v-list-item.mt-4(:class="{ 'menu-config--active': view === 'area-de-entrega' }",@click="changeView('area-de-entrega')")
            //-     v-list-item-content
            //-         v-list-item-title
            //-             span.fonte.font-weight-bold(:class="{ 'white--text': view === 'area-de-entrega' }") Área de Entrega
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
    v-card.expande-horizontal.pa-2.pt-6(min-height="300px" v-else)
        v-list.expande-horizontal.pa-0.ma-0(dense)
            //- v-list-item(:class="{ 'menu-config--active': view === 'infoloja' }",@click="changeView('infoloja')")
            //-     v-list-item-content
            //-         v-list-item-title
            //-             span.fonte.font-weight-bold(:class="{ 'white--text': view === 'infoloja' }") Informações da Loja
            //- v-list-item.mt-4(:class="{ 'menu-config--active': view === 'local' }",@click="changeView('local')")
            //-     v-list-item-content
            //-         v-list-item-title
            //-             span.fonte.font-weight-bold(:class="{ 'white--text': view === 'local' }") Consumo Local e Retirada
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
            v-list-item.mt-4(:class="{ 'menu-config--active': view === 'recibo' }",@click="changeView('recibo')")
                v-list-item-content
                    v-list-item-title
                        span.fonte.font-weight-bold(:class="{ 'white--text': view === 'recibo' }") Recibo
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
            v-list-item.mt-4(:class="{ 'menu-config--active': view === 'taxas' }",@click="changeView('taxas')")
                v-list-item-content
                    v-list-item-title
                        span.fonte.font-weight-bold(:class="{ 'white--text': view === 'taxas' }") Taxas
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
            //- v-list-item.mt-4(:class="{ 'menu-config--active': view === 'area-de-entrega' }",@click="changeView('area-de-entrega')")
            //-     v-list-item-content
            //-         v-list-item-title
            //-             span.fonte.font-weight-bold(:class="{ 'white--text': view === 'area-de-entrega' }") Área de Entrega
                //- v-list-item-action 
                //-     div.btn-menu-config
                        //- v-icon(size="16",color="white") mdi-check
</template>

<script>
import { EventBus } from "@/main";
export default {
  props: ["view"],
  data() {
    return {};
  },
  methods: {
    changeView(view) {
      EventBus.$emit("set-config-view", view);
    }
  }
};
</script>

<style>
.menu-config--active {
  width: 100%;
  height: 40px;
  background: #010f50;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #fff;
}
.btn-menu-config {
  width: 29px;
  height: 29px;
  background: #17b51c;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
